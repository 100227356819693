import {Injectable} from "@angular/core";
import {map} from "rxjs/operators";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {ActivatedRoute, Router} from "@angular/router";

@Injectable({
    providedIn: "root"
})
export class AuthService {

    constructor(private oidcSecurityService: OidcSecurityService, private router: Router, private route: ActivatedRoute) {
    }

    authGuardLogic() {
        return this.oidcSecurityService.checkAuth().pipe(
            map((auth) => {
                if (!auth) {
                    if (window.location.pathname !== "/" && window.location.search === "") {
                        localStorage.setItem("redirectAfterLogin", window.location.pathname);
                    }
                    this.oidcSecurityService.authorize();
                }
                if (auth) {
                    const allowedRoles = ["admin", "boadmin", "backoffice", "regulierer", "interessentinnen"];
                    const currentRole = this.oidcSecurityService.getPayloadFromIdToken()["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"].toLowerCase();
                    if (allowedRoles.indexOf(currentRole) <= -1) {
                        alert(`Rolle "${currentRole}" wird nicht unterstützt`);
                        return false;
                    } else if (localStorage.getItem("redirectAfterLogin") !== null) {
                        const redirectAfterLogin = localStorage.getItem("redirectAfterLogin");
                        localStorage.removeItem("redirectAfterLogin");
                        this.router.navigate([redirectAfterLogin]);
                    }
                }
                return auth;
            }));
    }
}
