import {
  APP_INITIALIZER,
  forwardRef,
  Injectable,
  LOCALE_ID,
  NgModule,
  Provider,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import {
  IonicModule,
  IonicRouteStrategy,
  Platform,
  ToastController,
} from "@ionic/angular";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { ApiModule } from "./api/api.module";
import { ApiConfiguration } from "./api/api-configuration";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import {
  AuthModule,
  LogLevel,
  OidcConfigService,
  OidcSecurityService,
} from "angular-auth-oidc-client";
import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "../environments/environment";
import { NgxImageCompressService } from "ngx-image-compress";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SuchePageModule } from "./kalkulation/suche/suche.module";
import { PositionModalPage } from "./kalkulation/position-modal/position-modal.page";
import { DatePipe, registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from "ngx-cookieconsent";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

registerLocaleData(localeDe);

export function configureAuth(oidcConfigService: OidcConfigService) {
  return () =>
    oidcConfigService.withConfig({
      stsServer: environment.identityServer,
      redirectUrl: environment.redirectUrl,
      postLogoutRedirectUri: window.location.origin,
      clientId: environment.clientId,
      scope: "openid profile phone permission introspect",
      responseType: "code",
      silentRenew: false,
      logLevel: LogLevel.Debug,
      maxIdTokenIatOffsetAllowedInSeconds: 30000,
      storage: localStorage,
    });
}

const port = window.location.port ? window.location.port : "443";
const rootUrl = window.location.hostname + ":" + port;

export function initApiConfiguration(config: ApiConfiguration): Function {
  return () => {
    config.rootUrl = window.location.protocol + "//" + rootUrl + "/api";
  };
}

export const INIT_API_CONFIGURATION: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initApiConfiguration,
  deps: [ApiConfiguration],
  multi: true,
};

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;

  constructor(
    private toastController: ToastController,
    private oidcSecurityService: OidcSecurityService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            const body = event.body;
            this.convertToDate(body);
          }
        },
        (err) => {
          if (!err.ok) {
            if (err.status === 401) {
              this.oidcSecurityService.logoff();
            }

            const toastPromise = this.toastController.create({
              message: `${err.message}`,
              duration: 2000,
            });

            toastPromise.then((toast) => {
              toast.present();
            });
          }
        },
      ),
    );
  }

  convertToDate(body) {
    if (body === null || body === undefined) {
      return body;
    }

    if (typeof body !== "object") {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (this.isIso8601(value)) {
        body[key] = new Date(value);
      } else if (typeof value === "object") {
        this.convertToDate(value);
      }
    }
  }

  isIso8601(value) {
    if (value === null || value === undefined) {
      return false;
    }

    return this.iso8601.test(value);
  }
}

export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => ApiInterceptor),
  multi: true,
};

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    // tslint:disable-next-line:max-line-length
    domain: window.location.hostname, // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  position: "bottom",
  theme: "classic",
  palette: {
    popup: {
      background: "#004899",
      text: "#ffffff",
      link: "#ffffff",
    },
    button: {
      background: "#ff980e",
      text: "#ffffff",
      border: "transparent",
    },
  },
  type: "opt-out",
  content: {
    message:
      "Wir verwenden Cookies, um unsere Website laufend an die Anforderungen unserer Nutzer anzupassen",
    allow: "Cookies akzeptieren",
    dismiss: "Cookies ablehnen",
    deny: "Cookies ablehnen",
    link: "",
    href: "",
    policy: "Cookies",
  },
};

@NgModule({
  declarations: [AppComponent, PositionModalPage],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ApiModule,
    ReactiveFormsModule,
    FormsModule,
    LoadingBarHttpClientModule,
    AuthModule.forRoot(),
    SuchePageModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
  ],
  providers: [
    NgxImageCompressService,
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    INIT_API_CONFIGURATION,
    ApiInterceptor,
    API_INTERCEPTOR_PROVIDER,
    { provide: LOCALE_ID, useValue: "de" },
    DatePipe,
    provideAnimationsAsync(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
