import { Injectable } from "@angular/core";
import { SendService } from "../api/services/send.service";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { AlertController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class EmailService {
  constructor(
    private sendService: SendService,
    private oidcSecurityService: OidcSecurityService,
    private alertController: AlertController,
  ) {}

  private sendMail(aktid) {
    this.sendService
      .sendStatusCreate({
        akt_id: aktid,
        token: this.oidcSecurityService.getToken(),
        submit_type: "email",
      })
      .subscribe(() => {
        alert("E-Mail gesendet");
      });
  }

  private sendVermittlerMail(aktid) {
    this.sendService
      .sendStatusCreate({
        akt_id: aktid,
        token: this.oidcSecurityService.getToken(),
        submit_type: "vermittleremail",
      })
      .subscribe(() => {
        alert("E-Mail gesendet");
      });
  }

  async sendEmailAlert(aktId) {
    const alert = await this.alertController.create({
      header: "Wollen Sie eine Statusinfo E-Mail verschicken?",
      message:
        "Bei Bestätigung wird eine E-Mail an den Versicherungsnehmer gesendet.",
      buttons: [
        {
          text: "Abbrechen",
          role: "cancel",
        },
        {
          text: "Senden",
          handler: () => {
            this.sendMail(aktId);
          },
        },
      ],
    });

    await alert.present();
  }

  async sendVermittlerEmailAlert(aktId) {
    const alert = await this.alertController.create({
      header:
        "Wollen Sie eine Statusinfo E-Mail an den Vermittler verschicken?",
      message: "Bei Bestätigung wird eine E-Mail an den Vermittler gesendet.",
      buttons: [
        {
          text: "Abbrechen",
          role: "cancel",
        },
        {
          text: "Senden",
          handler: () => {
            this.sendVermittlerMail(aktId);
          },
        },
      ],
    });

    await alert.present();
  }
}
