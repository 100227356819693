import { Component, OnInit } from "@angular/core";
import { KalkulationsPosition } from "../models/kalkulationsposition";
import { PositionModalPage } from "../position-modal/position-modal.page";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { DetailService } from "../../api/services/detail.service";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { ProxyService } from "../../api/services/proxy.service";
import { ModalController, NavController, Platform } from "@ionic/angular";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import {DetailAktDataRetrieveResponse} from '../../api/models/detail-akt-data-retrieve-response';

@Component({
  selector: "app-suche",
  templateUrl: "./suche.page.html",
  styleUrls: ["./suche.page.scss"],
})
export class SuchePage implements OnInit {
  rowsPerPage = 50;
  solrBaseUrl =
    "/solr/gruppen/select?f.thesaurus.facet.limit=1000000&facet=true&facet.field=Positionsgruppe&facet.field=Untergruppe&facet.field=Quelle&facet.field=Einheit&facet.field=Position&facet.field=Versicherungsunternehmen&facet.field=Aktarten&rows=" +
    this.rowsPerPage +
    "&wt=json";

  currentPageIndex = 0;
  aktid;
  snr;
  isForAkt;

  filters = this.fb.group({
    globalSearch: [""],
    facets: this.fb.group({
      Versicherungsunternehmen: [[]],
      Aktarten: [[]],
      Positionsgruppe: [[]],
      Untergruppe: [[]],
      Einheit: [[]],
      Quelle: [[]],
    }),
  });

  expandedFacets = {
    Versicherungsunternehmen: false,
    Aktarten: false,
    Positionsgruppe: true,
    Untergruppe: false,
    Einheit: false,
    Quelle: false,
  };
  aktDetail: DetailAktDataRetrieveResponse | undefined;

  get globalSearch(): UntypedFormControl {
    return this.filters.get("globalSearch") as UntypedFormControl;
  }

  get facets() {
    return this.filters.get("facets") as UntypedFormGroup;
  }

  get facetVersicherungsunternehmen() {
    return this.facets.get("Versicherungsunternehmen") as UntypedFormControl;
  }

  get facetAktarten() {
    return this.facets.get("Aktarten") as UntypedFormControl;
  }

  get facetPositionsgruppe() {
    return this.facets.get("Positionsgruppe") as UntypedFormControl;
  }

  get facetUntergruppe() {
    return this.facets.get("Untergruppe") as UntypedFormControl;
  }

  get facetEinheit() {
    return this.facets.get("Einheit") as UntypedFormControl;
  }

  get facetQuelle() {
    return this.facets.get("Quelle") as UntypedFormControl;
  }

  get currentPage() {
    return this.currentPageIndex + 1;
  }

  set currentPage(value) {
    this.currentPageIndex = value - 1;
  }

  pages: number[] = [];
  showPagination = false;

  solrResponse;
  isMobile;

  constructor(
    private fb: UntypedFormBuilder,
    public navCtrl: NavController,
    private detailService: DetailService,
    private route: ActivatedRoute,
    private oidcSecurityService: OidcSecurityService,
    private modalController: ModalController,
    private solrProxy: ProxyService,
    private platform: Platform,
    private router: Router,
    private pageTitle: Title,
  ) {}

  ngOnInit() {
    this.aktid = this.route.snapshot.queryParams.aktid;
    this.snr = this.route.snapshot.queryParams.snr;
    this.isForAkt = this.aktid && this.snr;

    if (!this.isForAkt) {
      this.pageTitle.setTitle("Kalkulation");
    }

    this.isMobile = this.platform.width() <= 576;

    this.globalSearch.valueChanges.subscribe(() => {
      this.search();
    });

    this.facetVersicherungsunternehmen.valueChanges.subscribe(() => {
      this.search();
    });

    this.facetAktarten.valueChanges.subscribe(() => {
      this.search();
    });

    this.facetPositionsgruppe.valueChanges.subscribe(() => {
      this.expandedFacets.Positionsgruppe = false;
      this.expandedFacets.Untergruppe = true;
      this.search();
    });

    this.facetUntergruppe.valueChanges.subscribe(() => {
      this.expandedFacets.Untergruppe = false;
      this.search();
    });

    this.facetEinheit.valueChanges.subscribe(() => {
      this.expandedFacets.Einheit = false;
      this.search();
    });

    this.facetQuelle.valueChanges.subscribe(() => {
      this.expandedFacets.Quelle = false;
      this.search();
    });

    this.search();

    const queryParams = this.route.snapshot.queryParams;
    if (queryParams.aktid && queryParams.snr) {
      this.detailService
        .detailRetrieveCreate({
          akt_id: queryParams.aktid,
          snr: queryParams.snr,
          token: this.oidcSecurityService.getToken(),
        })
        .subscribe((res) => {

          this.aktDetail = res;

          if (
            this.solrResponse.facet_counts.facet_fields.Versicherungsunternehmen.includes(
              res.Contacts.VU.Name.split(" ")[0],
            )
          ) {
            this.facetVersicherungsunternehmen.value[0] =
              res.Contacts.VU.Name.split(" ")[0];
          }
          if (
            this.solrResponse.facet_counts.facet_fields.Aktarten.includes(
              res.Type,
            )
          ) {
            this.facetAktarten.value[0] = res.Type;
          }
          this.search();
        });
    }
  }

  backToGesamtkalkulation() {
    this.router.navigate(["kalkulation"], {
      queryParams: this.route.snapshot.queryParams,
    });
  }

  private search() {
    let url = this.solrBaseUrl;
    url += "&start=" + this.currentPageIndex * this.rowsPerPage;
    url +=
      "&q=suche:" +
      (this.globalSearch.value ? "*" + this.globalSearch.value + "*" : "*");
    Object.keys(this.facets.controls).forEach((facet) => {
      const selectedValues = this.facets.get(facet).value as string[];
      const selectedValuesLength = selectedValues.length;
      if (selectedValuesLength > 0) {
        url += "&fq=" + encodeURI(facet) + ":(";
        selectedValues.forEach((selectedValue, index) => {
          url += '"' + selectedValue + '"';
          const last = selectedValuesLength - 1 === index;
          if (!last) {
            url += " OR ";
          }
        });
        url += ")";
      }
    });

    this.solrProxy
      .proxyCreate({ token: this.oidcSecurityService.getToken(), url })
      .subscribe((res) => {
        if (res) {
          this.initPagination(res);
          this.solrResponse = res;
        }
      });
  }

  private initPagination(solrResponse) {
    this.showPagination =
      solrResponse.response.numFound > solrResponse.response.docs.length;
    const numberOfPages = Math.ceil(
      solrResponse.response.numFound / this.rowsPerPage,
    );
    const pages = [];
    const offset = 5;
    const minPage = this.currentPage - offset;
    const maxPage = this.currentPage + offset;
    for (let i = 1; i < numberOfPages; i++) {
      if (minPage < i && i < maxPage) {
        pages.push(i);
      }
    }
    this.pages = pages;
  }

  openPage(page: number) {
    const pageIndex = page - 1;
    this.currentPageIndex = pageIndex;
    this.search();
  }

  useDocument(doc: KalkulationsPosition, i: number) {
    const positionen = this.allePositionen;
    doc.Menge = 1;
    this.createModal(doc);
  }

  async createModal(doc) {
    this.presentModal({ initialPosition: doc });
  }

  async presentModal(initialState) {
    const modal = await this.modalController.create({
      component: PositionModalPage,
      componentProps: {
        initialPosition: initialState.initialPosition,
      },
    });

    await modal.present();
  }

  get allePositionen(): KalkulationsPosition[] {
    let positionenJSON;
    if (this.isForAkt) {
      positionenJSON = localStorage.getItem("gesamtkalkulation_" + this.aktid);
    } else {
      positionenJSON = localStorage.getItem("gesamtkalkulation");
    }
    let positionen = [];
    if (positionenJSON) {
      positionen = JSON.parse(positionenJSON);
    }
    return positionen;
  }
}
