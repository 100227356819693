import { Injectable } from "@angular/core";
import { AbloeseService as ApiAbloeseService } from "../api/services/abloese.service";
import { ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";
import { AbloeseAktDataSaveRequest } from "../api/models/abloese-akt-data-save-request";
import { OidcSecurityService } from "angular-auth-oidc-client";

@Injectable({
  providedIn: "root",
})
export class AbloeseService {
  constructor(
    private apiAbloeseService: ApiAbloeseService,
    private route: ActivatedRoute,
    private oidcSecurityService: OidcSecurityService,
  ) {}

  private generateRequestBody(route: ActivatedRouteSnapshot) {
    const x = {
      akt_id: route.queryParams.aktid,
      snr: route.queryParams.snr,
    };

    if (this.oidcSecurityService.getToken().length) {
      return { ...x, ...{ token: this.oidcSecurityService.getToken() } };
    }

    if (route.queryParams.user && route.queryParams.password) {
      return {
        ...x,
        ...{
          username: route.queryParams.user,
          password: route.queryParams.password,
        },
      };
    }

    return x;
  }

  retrieveAbloeseData(route: ActivatedRouteSnapshot) {
    return this.apiAbloeseService.abloeseRetrieveCreate(
      this.generateRequestBody(route),
    );
  }

  saveAbloese(route: ActivatedRouteSnapshot, data: AbloeseAktDataSaveRequest) {
    const requestBody = this.generateRequestBody(route);
    const abloeseCreateData = { ...requestBody, ...data };
    return this.apiAbloeseService.abloeseCreate(abloeseCreateData);
  }

  deleteAbloese(route: ActivatedRouteSnapshot) {
    return this.apiAbloeseService.abloeseDelete(
      this.generateRequestBody(route),
    );
  }

  isUserPasswordLogin(): boolean {
    const queryParams = this.route.snapshot.queryParams;
    return queryParams.user && queryParams.password;
  }

  isCustomer(): boolean {
    return !this.hasToken() && !this.isUserPasswordLogin();
  }

  isBackoffice(): boolean {
    return (
      this.hasToken() &&
      this.oidcSecurityService.getPayloadFromIdToken()[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ] === "Backoffice"
    );
  }

  isBackofficeAdmin(): boolean {
    return (
      this.hasToken() &&
      this.oidcSecurityService.getPayloadFromIdToken()[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ] === "BOAdmin"
    );
  }

  isAdmin(): boolean {
    return (
      this.hasToken() &&
      this.oidcSecurityService.getPayloadFromIdToken()[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ] === "Admin"
    );
  }

  isRegulierer(): boolean {
    return (
      this.hasToken() &&
      this.oidcSecurityService.getPayloadFromIdToken()[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ] === "Regulierer"
    );
  }

  isFairApp(): boolean {
    const queryParams = this.route.snapshot.queryParams;
    return queryParams.fairApp;
  }

  hasToken(): boolean {
    return !!this.oidcSecurityService.getToken().length;
  }
}
